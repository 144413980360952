import React from "react";


function Footer () {
    return (
        <div className="footer">
           <p>Leila Torres | 2024 © Tous droits réservés</p>
        </div>
    )
}

export default Footer;